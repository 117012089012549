import React from "react";
const Terms = () => {
  return (
        <div className="flex flex-col space-y-10 lg:mt-12 lg:flex-row lg:space-y-0 lg:space-x-16 items-center max-w-[90rem] mx-auto lg:px-4">
          <div className="md:flex justify-center space-y-4 ">
            <div className="flex-col space-y-4 lg:w-10/12">
              <h1 className="text-[2rem] font-bold">
                GENERAL TERMS OF USE OF “SeedpodsDay” WEBSITE
              </h1>
              <div className="space-y-2 mt-5 text-[#606060] font-nonito">
                <div className="mt-5">
                  Use of “A guide to depression and its treatment” website
                  (aguidetodepression.net) and of its sub-domains (jointly
                  called hereinafter "the Website") is subject to acceptance of
                  the present general terms of use (called hereinafter "TOU").
                  By logging into and using the Website the Web surfer (called
                  hereinafter the "User") acknowledges that it has examined the
                  present TOU, declares that it accepts them without reserve,
                  and undertakes to comply with them.
                </div>
                <h6 className="font-bold">1. Purpose</h6>
                <div>
                  This Website is intended to give Users information about
                  depression and its treatment. In particular, LES LABORATOIRES
                  SERVIER makes available to the Users information in the field
                  of health, in compliance with the specific legal and statutory
                  obligations of pharmaceutical laboratories, and in particular
                  with the provisions of the Public Health Code. It is however
                  hereby stipulated that the Website does not constitute a
                  medical or remote health service system. Consequently,
                  information which is placed in it cannot be considered
                  equivalent to a medical opinion or diagnosis, or take the
                  place of a medical consultation with a health professional.
                  Similarly, this information cannot be interpreted as promotion
                  or advertising for products, whether or not they are sold by
                  LES LABORATOIRES SERVIER or the legal entities in its group.
                  For all information concerning one of these products, we
                  invite the Users to contact directly the legal entity holding
                  the marketing authorisation of the product in question.
                </div>
                <h6 className="font-bold">2. Intellectual property</h6>
                <div>
                  All the content available on the Website, including,
                  non-restrictively, the texts, graphics, pictograms, images,
                  photographs, illustrations, sounds, audio and video data, and
                  the Website's tree structure, browsing plan and logos, the
                  design and organisation of its headings, their titles, the
                  databases, their structure and content, the trademarks
                  (referred to hereinafter as "the Content") is the exclusive
                  property of LES LABORATOIRES SERVIER, and/or, if applicable,
                  of its licensors or partners, and as such are protected by
                  intellectual property law or by the provisions relating to
                  image rights. Only copying for private use is authorised
                  pursuant to the Intellectual Property Code. Consequently, any
                  commercial usage, representation, distribution, reproduction,
                  adaptation, translation or transformation, whether total or
                  partial, by any process whatsoever, of the Website and/or of
                  its component elements, any sharing of the Website's Content,
                  and any transfers to another Website without the prior,
                  written authorisation of LES LABORATOIRES SERVIER, is strictly
                  prohibited, pursuant to the provisions of article L.122-4 of
                  the Intellectual Property Code. All requests for authorisation
                  for reproduction or representation of any Content in the
                  Website must be sent to LES LABORATOIRES SERVIER, at the
                  following address: digitalmarketing@servier.com In addition,
                  the trademarks and logos shown on the Website are registered
                  trademarks, and cannot be used without the express
                  authorisation of their owner. This being so, the act of
                  representing, reproducing, distributing and redistributing
                  them, whether in whole or in part, on the basis of the
                  elements of the Website, without the holder's prior, written
                  authorisation, constitutes an infringement of copyright within
                  the meaning of the provisions of articles L713-2 and following
                  of the Intellectual Property Code. In any event, the User
                  undertakes to keep and to copy each mention of copyright or of
                  property rights indicated in all elements of the Website which
                  it uses.
                </div>
                <h6 className="font-bold">3. Processing of Personal Data</h6>
                <div>
                  LES LABORATOIRES SERVIER, in its capacity of data controller
                  within the meaning of law no. 78-17 of 6 January 1978 relative
                  to Computing, files and freedom (known as the "computing and
                  freedom" law), amended, may collect and process personal data
                  of Users of the Website. The User is informed that its
                  personal data will be used by the services and personnel of
                  LES LABORATOIRES SERVIER and of other legal entities in the
                  Servier Group which need to know of it; Suppliers and
                  service-providers of LES LABORATOIRES SERVIER, in particular
                  those responsible for hosting the Website, and producing the
                  Website; which may access the Users' personal data for
                  purposes strictly required for the performance of their task.
                  LES LABORATOIRES SERVIER is obliged to communicate some of
                  your personal data to the competent authorities such as the
                  health authorities. Pursuant to the to regulations in force,
                  LES LABORATOIRES SERVIER undertakes to keep Users' personal
                  data only for the period required to accomplish the purposes
                  sought. LES LABORATOIRES SERVIER may keep the Users' Personal
                  Data for a longer term, pursuant to that which is authorised
                  or required by the applicable law, or if this is necessary to
                  protect its rights and interests. After this term personal
                  data shall be deleted or archived pursuant to the applicable
                  legal rules. Pursuant to the provisions of law no. 78-17 of 6
                  January 1978 relative to Computing, files and freedom (known
                  as the "computing and freedom" law), amended, each User has:
                  <ul>
                    <li>
                      • The right to view and to access, to update, to complete
                      and to rectify its personal data;
                    </li>
                    <li>
                      • The right to have its personal data erased and deleted,
                      on the terms defined by the applicable regulations and
                      legislation;
                    </li>
                    <li>
                      • The right to withdraw, at any time, its consent to
                      collection of its personal data;
                    </li>
                    <li>
                      • The right to object to the processing of all or part of
                      its personal data;
                    </li>
                    <li>
                      • The right to restrict processing of its personal data;
                    </li>
                    <li>
                      • The right to portability and to have its personal data
                      transferred in a structured format which is commonly used
                      and machine-readable, when this data is subject to
                      automated processing based on its consent;
                    </li>
                    <li>
                      • The right to define how its data is used after its
                      death, and to choose the third party to which LES
                      LABORATOIRES SERVIER must or must not communicate it. Each
                      of these rights can be exercised by sending a request to
                      the Data Protection Officer (DPO) with the following
                      contact details: - using the contact form available on the
                      Website, using which the User is asked to enter its name
                      and its contact details in order to enable a reply to be
                      sent to it, or - by email to the following address:
                      dataprivacy@servier.com - by postal letter to the
                      following address: Data Protection Officer Les
                      Laboratoires Servier 50 rue Carnot 92284 Suresnes Cedex
                      All information relative to processing of Users' personal
                      data is defined in the Policy relative to protection of
                      your personal data accessible via the following URL
                      <a
                        className="underline text-blue-400"
                        href="https://www.aguidetodepression.net/data-privacy-policy/"
                      >
                        https://www.aguidetodepression.net/data-privacy-policy/
                      </a>
                      .
                    </li>
                  </ul>
                   
                </div>
                <h6 className="font-bold">4. Cookies</h6>
                <div>
                  Users are informed that when they browse the Website
                  information may be stored in their browser or recovered from
                  it, generally in the form of cookies. This information can
                  relate to the User, the type of browser used, its browsing
                  preferences, the Website (pages viewed, date and time of
                  login, etc.) or its terminal (computer, tablet, smartphone,
                  etc.), and is used principally to ensure that the Website is
                  operating correctly. Cookies do not enable LES LABORATOIRES
                  SERVIER to identify Users personally, but to collect
                  information in a general manner when they visit the Website,
                  and enable them to have a personalised Web experience.   LES
                  LABORATOIRES SERVIER undertakes not to communicate the content
                  of these cookies to third parties, unless they have an
                  obligation to do so, as ordered by a judicial or
                  administrative authority. When it logs on to the Website the
                  User is expressly invited to accept use of cookies present on
                  the Website. Similarly, when the User clicks on the icons
                  dedicated to the social networks (such as, for example,
                  Twitter, Facebook or Linkedin) present in the Website, and if
                  it has accepted to receive cookies when browsing, these social
                  networks can also leave cookies in its terminal. The User can
                  block the use of all or some of the cookies, or delete cookies
                  previously installed in its browser: - either via the cookie
                  management configurator available at{" "}
                  <a
                    href="https://www.
                  aguidetodepression.net/#tarteaucitron"
                    className="underline text-blue-400"
                  >
                    https://www. aguidetodepression.net/#tarteaucitron
                  </a>
                  , to enable it to obtain details on each category of cookies
                  used and/or left on the Website, and to manage its
                  preferences; - or by modifying its browsing parameters by
                  viewing the help menu of the Internet browser used (Microsoft
                  Internet Explorer, Apple Safari, Google Chrome, Mozilla
                  Firefox, Opera, etc.), in particular in order to accept or
                  refuse all cookies, to be informed when a cookie is issued, to
                  view its validity, its duration and its content, and
                  periodically to delete cookies. Blocking certain types of
                  cookies or deleting them may affect access to certain
                  functions or pages of the Website or make this impossible, or
                  make certain services offered on the Website inaccessible, for
                  which LES LABORATOIRES SERVIER cannot be held liable.
                </div>
                <h6 className="font-bold">5. Use of the Website</h6>
                <div>
                  Users undertake to use the Website:
                  <ul>
                    <li>• in accordance with its intended use; </li>
                    <li>
                      • for private use, excluding all commercial or promotional
                      activities, or activities for professional, promotional,
                      marketing or commercial purposes;
                    </li>
                    <li>
                      • in accordance with copyrights and all other types of
                      intellectual property rights, in particular by preserving
                      each indication of copyright or of ownership right
                      mentioned in all elements of the Website which it uses;
                    </li>
                    <li>
                      • without using a robot or any other automated means to
                      access and use the contents of the Website, and without
                      attempting to impede the Website;
                    </li>
                    <li>
                      • without attempting to copy it, to reproduce it in whole
                      or in part, to make it accessible or to distribute it or
                      to share it by any means whatsoever with unauthorised
                      third parties. In addition, the information supplied on
                      the Website is non-contractual and cannot be considered to
                      be offers for services or products.
                    </li>
                  </ul>
                  Under no circumstances do they constitute an assertion, a
                  guarantee or an undertaking by LES LABORATOIRES SERVIER
                  concerning the products and services which are shown in it.
                  The User is also informed that information published on the
                  Website cannot be considered equivalent to medical advice, and
                  cannot be used in place of the advice of a doctor.
                  Consequently, Users must under no circumstances use this
                  information to establish a medical diagnosis or to recommend a
                  treatment, and must consult a health professional authorised
                  to dispense medical care. LES LABORATOIRES SERVIER cannot be
                  held liable for any decision taken on the basis of any
                  information contained in the Website, nor for any use which
                  may be made of it by third parties. It should also be noted
                  that each user of the Website is responsible for taking all
                  appropriate measures to protect and secure its own data and/or
                  applications against any intrusion or contamination by any
                  viruses present on the Internet.
                </div>
                <h6 className="font-bold">
                  6. Accuracy and comprehensiveness of Website information
                </h6>
                <div>
                  All information supplied to the Users on the Website is
                  provided "as is", without any guarantee of any kind, whether
                  expressly or tacitly, including, but not limited to, implicit
                  guarantees of merchantable quality, aptitude for a particular
                  use, or absence of infringement of copyright. LES LABORATOIRES
                  SERVIER strives to ensure, as far as it is able, that the
                  information distributed on the Website is accurate and
                  up-to-date, when it is onlined. However, it does not guarantee
                  in any way the truthfulness, completeness, accuracy or
                  comprehensiveness of the information and content made
                  available to the Users on the Website, and reserves the right
                  to amend or to correct, at any time and without notice, the
                  content of information and documents published on the Website.
                  Consequently, LES LABORATOIRES SERVIER declines all liability
                  for any imprecision, inaccuracy, omission or modification
                  relating to information available on Website, in particular in
                  the event of a change of the statutory or legal provisions,
                  and due to any damage resulting from a fraudulent intrusion by
                  a third party, in particular when it could lead to a
                  modification of the information made available on the Website.
                  With this regard, LES LABORATOIRES SERVIER cannot be held
                  liable for the content of the present pages, or for any use
                  which may be made of it by the User, to wit in respect of all
                  direct or indirect damage caused by access to or use of the
                  Website by any party or, alternatively, due to it being
                  impossible to log on to it.
                </div>
                <h6 className="font-bold">7. Modification of the Website</h6>
                <div>
                  LES LABORATOIRES SERVIER reserves the right to modify the
                  content of the Website and the data or information accessible
                  via the Website and the present General Terms of Use, in
                  particular in order to comply with all new applicable
                  standards, legislation and/or regulations, and/or to improve
                  Website performance. In this case the amended General Terms of
                  Use shall come into force from the date on which they are
                  onlined. The User is therefore recommended to keep itself
                  regularly informed of the Website's General Terms of Use.
                </div>
                <h6 className="font-bold">8. Availability of the Website</h6>
                <div>
                  LES LABORATOIRES SERVIER strives to keep the Website
                  accessible to Users 24 hours a day, 7 days a week. However,
                  LES LABORATOIRES SERVIER cannot guarantee the Website's
                  permanent availability and accessibility. This being so, LES
                  LABORATOIRES SERVIER reserves the right to cancel, restrict,
                  suspend or temporarily interrupt partial or total access to
                  the Website, to its functions, or to all or part of the
                  services offered on the Website, at any time, without notice
                  or compensation, for any reason whatsoever, and in particular
                  for technical maintenance reasons, on occurrence of an event
                  of force majeure or unforeseen accident, IT problems,
                  difficulties relating to the structure of the communication
                  networks, or of any other technical difficulties.
                  Notwithstanding all the means used by LES LABORATOIRES SERVIER
                  and its technical service-providers, the User is informed that
                  the Internet network is not reliable, above all in terms of
                  relative security in terms of data transmission, of
                  unguaranteed continuity of access to the Website, of
                  unguaranteed performance in terms of speed of data
                  transmission, and of propagation of viruses. It is also
                  stipulated hereby that the Internet network and IT and
                  telecommunications systems are not error-free, and that
                  interruptions and malfunctions can occur. Consequently, LES
                  LABORATOIRES SERVIER gives no guarantee with this regard, and
                  cannot consequently be held liable for any damage inherent to
                  the said uses of the Internet network, and of the IT and
                  telecommunications systems, in particular, although this list
                  is not restrictive: - poor transmission and/or reception of
                  all data and/or information on the Internet; - external
                  intrusion or the presence of computer viruses; - failure of
                  all reception equipment or of communication lines; - any other
                  malfunction of the Internet network preventing satisfactory
                  operation of the Website.
                </div>
                <h6 className="font-bold">
                  9. Liability of LES LABORATOIRES SERVIER
                </h6>
                <div>
                  LES LABORATOIRES SERVIER cannot be held liable for any direct
                  or indirect damage, such as, although this list is not
                  restrictive, all data losses or corruption, loss of programs,
                  loss of profits, or operating loss, incurred by a User or any
                  third party, resulting from access to the Website, from
                  browsing on the Website, from the content of the Website, from
                  use of the Website and of the Websites which are connected to
                  it, or due to it being impossible to use the content,
                  information and services offered via the Website by the Users.
                  In particular, LES LABORATOIRES SERVIER declines all liability
                  for damage of any kind whatsoever resulting:
                  <ul>
                    <li>
                      • from a typographical or formatting error, imprecision,
                      inaccuracy or omission relating to the information
                      accessible on the Website;
                    </li>
                    <li>
                      • from a fraudulent intrusion by a third party having led
                      to a modification of the information, documents or
                      elements made available on the Website;
                    </li>
                    <li>
                      • from access by any party to the Website or from it being
                      impossible to access it;
                    </li>
                    <li>
                      • from a failure, malfunction or incompatibility of the
                      User's IT equipment;
                    </li>
                    <li>
                      • from an interruption of the networks providing access to
                      the Website, from total or partial unavailability of the
                      Website, resulting in particular from telecommunications
                      operation;
                    </li>
                    <li>
                      • from the distribution or introduction of computer
                      viruses, Trojans or worms, through the Website, which may
                      affect the User's computer equipment;
                    </li>
                    <li>
                      • from credit given to any information derived directly or
                      indirectly from the Website, from use of it, or from the
                      use of a product to which it makes reference. LES
                      LABORATOIRES SERVIER declines all liability, express or
                      implicit, if use of the information on the Website
                      infringes a patent, a copyright or a registered trademark.
                    </li>
                  </ul>
                </div>
                <h6 className="font-bold">10. User's liability</h6>
                <div>
                  If content on the Website is distributed by the User, whatever
                  the nature thereof, the latter undertakes not to commit any
                  actions or make any remarks which are contrary to laws, public
                  order or public decency, or which are offensive, defamatory or
                  discriminatory in nature, or threatening towards a person or a
                  group of persons, or which breach the provisions of copyright,
                  image rights, the private life of other people, medical
                  secrecy or secrecy of correspondence. It may be held liable
                  for any breach of this undertaking. In general, the User
                  undertakes to act respectfully, in particular with regard to
                  the other members of the Website. The User is informed that in
                  the event of a request by the competent authorities LES
                  LABORATOIRES SERVIER is authorised to transmit all login data
                  allowing identification of the User, if the latter appears to
                  be at the origin of illegal content or actions. 11. Hypertext
                  links The present Website may contain links to other Websites
                  published by third parties. The presence of a hypertext link
                  leading to a third-party Website does not under any
                  circumstances constitute an approval of the Website or of its
                  content by LES LABORATOIRES SERVIER. LES LABORATOIRES SERVIER
                  exercises no control over these third-party Websites, and
                  consequently declines any liability regarding the content of
                  these Websites and any use which may be made of them by any
                  User. In addition, LES LABORATOIRES SERVIER cannot be held
                  liable as a consequence of information, opinions and
                  recommendations made by third parties. The User accesses and
                  uses third-party Websites at its sole liability, at its own
                  risk, and in accordance with the applicable terms of use on
                  the said Websites. Users are consequently advised to check the
                  terms of use and the confidentiality charters of these
                  Websites before providing them with personal information.
                </div>
                <h6 className="font-bold">11. Applicable law</h6>
                <div>
                  The present TOU are governed by French law, in respect both of
                  the substantial rules and the formal rules. All disagreements
                  shall be brought before the courts with jurisdiction of Paris
                  (France). Any application of the rules of conflict of laws
                  which restricts full application of French law is consequently
                  excluded hereby. Consequently, French law applies to all Users
                  which browse the Website and which use all or part of its
                  functions. In the event of a divergence between the
                  information presented in the French version of the Website and
                  that presented in the English version of the said Website, the
                  information presented in the Website's French version shall
                  take precedence.
                </div>
                <h6 className="font-bold">12. Legal mentions:</h6>
                <div>
                  The “A guide to depression and its treatment” website is
                  published by LES LABORATOIRES SERVIER, a company with capital
                  of 3.080.000 Euros, registered in France Trade and Companies
                  Register as number 085 480796 RCS Nanterre, having its
                  registered office at 50 rue Carnot – 92284 Suresnes – France.
                  The director of the Publication is Mr. Pierre GAILLARD, Head
                  of Global Marketing &amp; Market Access in his capacity as
                  Servier representative. “A guide to depression and its
                  treatment” website is hosted by OVH, a simplified joint-stock
                  company with a capital of 10 069 020 €, registered with the
                  Trade and Companies’ Register of Lille Métropole under number
                  424 761 419 00045, the head office of which is located at 2
                  rue Kellermann – 59100 Roubaix – France. The design and
                  production of the Website “A guide to depression and its
                  treatment” website has been undertaken by LICORN PUBLISHING,
                  SARL, having its registered office at 101 rue de Sèvres, 75006
                  Paris – France –{" "}
                  <a
                    href="https://licornpublishing.com/"
                    className="underline text-blue-400"
                  >
                    https://licornpublishing.com/
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};
export default Terms;
