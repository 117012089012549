import axios from "axios";
import { useState } from "react";
import { setIsAuthenticated } from "../lib/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

export const LoginForm = ({
    setLoading,
    loading,
    setView,
    setShowTerms,
    setShowPrivacy,
    setShowConfidentiality,
    showValidationStatus
}) => {
    const [ showPassword, setShowPassword ] = useState(false);

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ consentChecked, setConsentChecked ] = useState(false);

    const [ isError, setIsError ] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleLoginSubmit = async (e) => {

        e.preventDefault();
        setLoading(true);
        const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/subscribers/login`,
            {
                email,
                password
            }
        );

        if (data.success) {
            setIsAuthenticated(true);
            window.location.href = '/'
        } else {
            setIsError(true)
        }

        setLoading(false);
    };
    return (
            <form className="w-full p-5" onSubmit={ handleLoginSubmit }>
                <h1 className="text-[#1C1A50] font-bold text-[32px] text-base poppins font-bold text-center mt-[30px]">
                    Login
                </h1>
                <div className="mx-auto max-w-lg mb-4 poppins w-[394px]">
                    <div className="w-full md:mt-6 mt-4 rounded-2xl ">
                    <label
                        className=" text-[#1C1A50] font-normal"
                        htmlFor="grid-first-name"
                    >
                        Email <span className="text-[#CC2D2D]">*</span>
                    </label>
                        <TextField
                            required={ true }
                            className={`block w-full   font-normal text-sm placeholder-black text-black  focus:outline-none `}
                            id="first-name"
                            type="text"
                            placeholder="someone@example.com..."
                            value={ email }
                            onChange={ (e) => setEmail(e.target.value) }
                            InputProps={{
                                className: ' border-[#323C8E] rounded-lg bg-white'
                            }}
                        />

                    </div>
                    
                    <div className="w-full md:mt-6 mt-4 rounded-2xl ">
                    <label
                        className=" text-[#1C1A50] font-normal"
                        htmlFor="grid-first-name"
                    >
                        Password <span className="text-[#CC2D2D]">*</span>
                    </label>
                        <TextField
                            className={`block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg `}
                            
                            type={ showPassword ? "text" : "password" }
                            value={ password }
                            onChange={ (e) => setPassword(e.target.value) }
                            required={ true }
                            InputProps={ {
                                className: 'border-[#323C8E] rounded-lg bg-white',
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ handleClickShowPassword }
                                            edge="end"
                                        >
                                            { showPassword ? <VisibilityOff /> : <Visibility /> }
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            } }
                            fullWidth
                        />

                    </div>
                    <div className="md:text-sm text-xs font-normal">
                        <input
                            className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                            name="consent"
                            id="consentCheckbox"
                            type="checkbox"
                            value="yes"
                            checked={ consentChecked }
                            onChange={ (e) => setConsentChecked(e.target.checked) }
                        />
                        I agree to the{ " " }
                        <span
                            className="underline hover:text-gray-500 hover:cursor-pointer"
                            onClick={ () => setShowConfidentiality(true) }
                        >
                            confidentiality commitment
                        </span>
                        ,{ " " }
                        <span
                            className="underline hover:text-gray-500 hover:cursor-pointer"
                            onClick={ () => setShowTerms(true) }
                        >
                            terms and conditions
                        </span>{ " " }
                        and the{ " " }
                        <span
                            className="underline hover:text-gray-500 hover:cursor-pointer"
                            onClick={ () => setShowPrivacy(true) }
                        >
                            privacy policy
                        </span>
                    </div>
                    { isError && (

                        <div className="w-full mt-4 flex mt-[10px] rounded-2xl text-[#973939]">
                            Oops! It seems there's an issue with your email or password.
                        </div>

                    ) }

                    {
                        showValidationStatus && (
                            <div className="w-full mt-4 flex mt-[10px] rounded-2xl text-[#973939] success box box1">
                                Registration successfully validated.
                            </div>
                        )
                    }

                    <div className="w-full mt-4 flex">
                        <a href="/reset-password" className="underline hover:text-gray-500 hover:cursor-pointer text-[#1C1A50] leading-[45px]">Forgot your password?</a>
                        <button
                            className="flex mx-auto bg-[#24226A] text-white py-3 px-10 md:font-semibold font-normal rounded-lg justify-end"
                            type="submit"
                            disabled={ loading || !consentChecked }
                            style={ loading || !consentChecked ? { opacity: "0.3" } : {} }
                        >
                            { loading && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    className={ `motion-reduce:hidden animate-spin mr-[10px]` }
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                            ) }
                            Login
                        </button>

                    </div>
                    <div className="w-full mt-4 flex">
                        <button
                            onClick={ () => setView('register') }
                            className="mx-auto bg-none text-[#1C1A50] py-3 px-10 md:font-semibold font-normal rounded-lg"
                        >
                            Register here
                        </button>
                    </div>
                </div>


            </form>

    )
};