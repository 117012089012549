import {
  allStringsToLowercase,
  getLastPathElement,
  removeUndefined,
} from "./utils";

export const trackEvent = ({
  eventName,
  elementCategory,
  elementName,
  pageName,
  userLoggued
}) => {
  if (!window?.dataLayer || process.env.REACT_APP_ENABLE_TRACK === "false") return;

  const eventData = allStringsToLowercase(
    removeUndefined({
      event: eventName,
      element_category: elementCategory,
      element_name: elementName,
      page_name: pageName,
      country: 'France',
      language: navigator.language || navigator.userLanguage || "fr-FR",
      asset: "seedpods",
      environment_type: process.env.REACT_APP_ENV,
      user_loggued: userLoggued
    })
  );

  window.dataLayer.push(eventData);
};

export const trackPageview = ({
  pageName,
  userLoggued
}) => {
  if (!window?.dataLayer  || process.env.REACT_APP_ENABLE_TRACK === "false") return;
  window.dataLayer.push(
    allStringsToLowercase(
      removeUndefined({
        event: "page_view",
        user_loggued: userLoggued,
        page_name: pageName,
        country: 'France',
        language: navigator.language || navigator.userLanguage || "fr-FR",
        asset: "seedpods",
        environment_type: process.env.REACT_APP_ENV,
      })
    )
  );
};

