import { TextField } from "@mui/material";
import { useState } from "react";
import axios from "axios";

export const ResetPassword = ({

}) => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ response, setResponse ] = useState();

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleUpdatePasswordSubmit = async (event) => {
        event.preventDefault();

        // Password validation
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        // Password reset logic here
        // For example, send password to server for processing
        // You can add your own logic here

        const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/reset-password/update`,
            {
                token,
                password
            }
        );

        if (data.success) {
            setResponse("Your password has been updated successfully. You will be redirected to the login page");
            setTimeout(() => { window.location.href = "/" }, 5000)
        } else {
            setResponse("An error occured please try again later");
        }

        // Reset form fields and errors
        setPassword('');
        setConfirmPassword('');
        setPasswordError('');
    };

    const handleResetSubmit = async (event) => {
        event.preventDefault();
        const { data } = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/reset-password/reset`,
            {
                email
            }
        );

        if (data.success) {
            setResponse("An email has been sent with reset link");

        } else {
            setResponse("An error occured please try again later");
        }
    }


    return (
        <div className="flex flex-col">

            { !token && (<form className="w-full p-5" onSubmit={ handleResetSubmit }>
                <h1 className="text-[#1C1A50] font-bold text-[32px] text-base poppins font-bold text-center mt-[30px]">
                    Reset Password
                </h1>
                <div className="mx-auto max-w-lg mb-4 poppins w-[394px] mt-[20px]">
                    <div className="w-full md:mt-6 mt-4 rounded-2xl ">
                        <label
                            className=" text-[#1C1A50] font-normal"
                            htmlFor="grid-first-name"
                        >
                            Email <span className="text-[#CC2D2D]">*</span>
                        </label>
                        <TextField
                            required={ true }
                            className={ `block w-full   font-normal text-sm placeholder-black text-black  focus:outline-none ` }
                            id="email"
                            type="text"
                            placeholder="someone@example.com..."
                            value={ email }
                            onChange={ (e) => setEmail(e.target.value) }
                            InputProps={ {
                                className: ' border-[#323C8E] rounded-lg bg-white'
                            } }
                        />

                    </div>
                    <div className="flex justify-end mt-[10px] mb-[10px]">
                        <button
                            className="bg-[#24226A] text-white py-3 px-10 md:font-semibold font-normal rounded-lg"
                            type="submit"
                        >

                            Reset
                        </button>
                    </div>
                   
                </div>
            </form>) }
            { token && (<form className="w-full p-5" onSubmit={ handleUpdatePasswordSubmit }>
                <h1 className="text-[#1C1A50] font-bold text-[32px] text-base poppins font-bold text-center mt-[30px]">
                    Reset Password
                </h1>

                <div className="mx-auto max-w-lg mb-4 poppins w-[394px] mt-[20px]">
                    <label htmlFor="password">New Password:</label>
                    <TextField
                        className={ `block w-full   font-normal text-sm placeholder-black text-black  focus:outline-none ` }
                        type="password"
                        id="password"
                        value={ password }
                        onChange={ handlePasswordChange }
                        required
                        InputProps={ {
                            className: ' border-[#323C8E] rounded-lg bg-white'
                        } }
                    />
                </div>
                <div className="mx-auto max-w-lg mb-4 poppins w-[394px]">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <TextField
                        className={ `block w-full   font-normal text-sm placeholder-black text-black  focus:outline-none ` }
                        type="password"
                        id="confirmPassword"
                        value={ confirmPassword }
                        onChange={ handleConfirmPasswordChange }
                        required
                        InputProps={ {
                            className: ' border-[#323C8E] rounded-lg bg-white'
                        } }
                    />
                </div>
                { passwordError && <div style={ { color: 'red' } }>{ passwordError }</div> }
                <div className="flex justify-end mt-[10px] mb-[10px]">
                    <button type="submit" className="bg-[#24226A] text-white py-3 px-10 md:font-semibold font-normal rounded-lg mb-[10px]">Reset Password</button>
                </div>
                
            </form>) }
            { response && (
                    <div className="w-full  rounded-2xl bg-gray-100 border border-white success box box1">
                        { response }
                    </div>
                ) }
        </div>

    );
}