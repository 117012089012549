import React from "react";
import { useState } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import { GET_LOGIN_PAGE_DATA } from "../lib/api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { LoginForm } from "../components/LoginForm";
import { RegisterForm } from "../components/RegisterForm";
import Terms from "../components/terms";
import Privacy from "../components/privacy";
import Confidentiality from "../components/Confidentiality";
import { trackPageview } from "../lib/gmt";
import { ResetPassword } from "../components/ResetPassword";
import LegalPage from "../components/LegalPage";

export const LoginPage = ({ data }) => {
  
  const history = useHistory();
  const location = useLocation();
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState("");
  const [ showTerms, setShowTerms ] = useState(false);
  const [ showPrivacy, setShowPrivacy ] = useState(false);
  const [ showConfidentiality, setShowConfidentiality ] = useState(false);
  const [ showSignupValidate, setShowSignupValidate ] = useState(false);

  const [ view, setView ] = useState();

  let { userId } = useParams();


  const validateUser = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/subscribers/validate/${userId}`,
      { email: email }
    );
    setShowSignupValidate(result.data.success)
    /*setTimeout(() => {
      setShowSignupValidate(false)
    }, 5000)*/
  }

  React.useEffect(() => {
    if (location.pathname === "/reset-password") {
      setView("resetPassword");
    } else {
      setView(data?.loginPage?.data?.attributes.enableLogin ? "login" : "register")
    }
    if (userId) {
      validateUser();
    }
  }, []);

  React.useEffect(() => {
    trackPageview({
      pageName: userId ? "Register confirmation page" : "login" === view ? "Login page" : "Register page",
      userLoggued: "No",
    });
  }, [ view, userId ]);


  return (
    <div className={ `bg-gradient-to-tr flex justify-center from-[#292929] to-[#292929] w-full bg-cover bg-center relative flex flex-wrap` }>

      {/* form */ }
      <div className={ `lg:col-span-2 lg:row-span-3 justify-center py-10 w-full  sm:w-6/12  bg-[#aec3ed] flex  ` }>
        <div className={ `flex justify-center items-center w-[600px] mx-auto ${view === "login" ? 'h-screen' : ''}` }>

          { view === "login" && (
            <div className="h-full bg-[#aec3ed] flex justify-center items-center">
              <LoginForm showValidationStatus={ showSignupValidate } setShowPrivacy={ setShowPrivacy } setShowTerms={ setShowTerms } setShowConfidentiality={ setShowConfidentiality } loading={ loading } setLoading={ setLoading } setView={ setView } view={ view } />
            </div>) }
          { view === "register" && (
            <RegisterForm showValidationStatus={ showSignupValidate } enableLogin={ data?.loginPage?.data?.attributes.enableLogin } setShowPrivacy={ setShowPrivacy } setShowTerms={ setShowTerms } setShowConfidentiality={ setShowConfidentiality } setView={ setView } view={ view } loading={ loading } setLoading={ setLoading } quota={ data?.quota?.data?.attributes.initialQuota } />
          )
          }
          { view === "resetPassword" && (
            <ResetPassword />
          )
          }
        </div>
      </div>
      <div className={ `flex flex-col w-full sm:w-6/12  p-12 pb-0 bg-[url('/images/bg-new.jpeg')] bg-cover bg-bottom` } style={ { backgroundImage: "url('/images/bg-new.jpg')" } }>
        <div className="lg:col-span-4 lg:row-span-3">
          <div className=" md:w-11/12 md:pr-1 ">
            <div className="md:w-64 w-64 mt-10 mx-auto">
              <img src="/images/logo-white.png" alt="" />
            </div>
            <div className="md:pt-1 pt-1  ">
              <p className=" md:text-3xl text-3xl  font-semibold  text-white md:leading-[64px] leading-8 roboto text-justify ">
                { data?.loginPage?.data?.attributes.Welcome }
              </p>
            </div>
            <div className="md:pt-6 pt-2">
              <p className="md:font-semibold font-medium md:text-xl text-sm md:leading-8 leading-5  text-[#469DC3] ">
                { data?.loginPage?.data?.attributes.EventDateAndLocation }
              </p>
            </div>
            <p className=" font-normal md:text-lg text-xs  md:leading-6 leading-4 text-white text-justify">
              <span className="flex flex-col mb-[40px]" dangerouslySetInnerHTML={ { __html: data?.loginPage?.data?.attributes.Presentation } } />
            </p>

            <div className="md:pt-2 pt-1  ">
              <p className=" md:text-2xl text-1xl  font-semibold  text-white md:leading-[64px] leading-8 roboto  ">
                { data?.loginPage?.data?.attributes.TitleServier }
              </p>
            </div>
            <p className="font-normal md:text-lg text-xs  md:leading-6 leading-4 text-white text-justify">
              <span className="flex flex-col mb-[40px]" dangerouslySetInnerHTML={ { __html: data?.loginPage?.data?.attributes.PresentationServier } } />
            </p>

            <div className="md:pt-2 pt-1  ">
              <p className=" md:text-2xl text-1xl  font-semibold  text-white md:leading-[64px] leading-8 roboto  ">
                { data?.loginPage?.data?.attributes.TitleShineDocs }
              </p>
            </div>
            <p className=" font-normal md:text-lg text-xs  md:leading-6 leading-4 text-white text-justify">
              <span className="flex flex-col mb-[40px]" dangerouslySetInnerHTML={ { __html: data?.loginPage?.data?.attributes.PresentationShinedocs } } />
            </p>
            
          </div>
          <div className="flex justify-center items-center my-8 gap-10">
            <img
              className="w-64"
              src={ data?.logo?.data?.attributes.logoSeedpods.data.attributes.url }
              alt=""
            />
            <img
              className="h-24"
              src={ data?.logo?.data?.attributes.shinedocsYellow.data.attributes.url }
              alt=""
            />
          </div>

        </div>


      </div>
      { (showPrivacy ||
        showTerms ||
        showConfidentiality) && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">

            <div className="bg-white w-4/5 h-4/5 rounded-lg shadow-lg p-6 overflow-auto">
              <button
                onClick={ () => {
                  setShowTerms(false);
                  setShowConfidentiality(false);
                  setShowPrivacy(false);
                } }
              >
                <svg
                  className="absolute z-50"
                  style={ { right: "20px", top: "20px" } }
                  width="18"
                  height="18"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 4.57916L1.72731 0.296389C1.63335 0.202432 1.52181 0.127901 1.39905 0.0770521C1.27628 0.0262027 1.14471 2.92689e-05 1.01183 2.92718e-05C0.878957 2.92747e-05 0.747383 0.0262027 0.624621 0.0770521C0.50186 0.127902 0.390316 0.202432 0.296359 0.296389C0.202402 0.390347 0.12787 0.50189 0.0770208 0.624651C0.0261714 0.747412 -2.43058e-07 0.878987 -2.40154e-07 1.01186C-2.3725e-07 1.14474 0.0261714 1.27631 0.0770208 1.39908C0.12787 1.52184 0.202402 1.63338 0.296359 1.72734L4.57913 6.00003L0.296359 10.2727C0.106604 10.4625 -2.99788e-08 10.7198 -2.21143e-08 10.9882C-1.42498e-08 11.2566 0.106604 11.5139 0.296359 11.7037C0.486115 11.8934 0.743479 12 1.01183 12C1.28019 12 1.53755 11.8934 1.72731 11.7037L6 7.4209L10.2727 11.7037C10.4624 11.8934 10.7198 12 10.9882 12C11.2565 12 11.5139 11.8934 11.7036 11.7037C11.8934 11.5139 12 11.2566 12 10.9882C12 10.7198 11.8934 10.4625 11.7036 10.2727L7.42087 6.00003L11.7036 1.72734C11.8934 1.53758 12 1.28022 12 1.01186C12 0.743508 11.8934 0.486145 11.7036 0.296389C11.5139 0.106633 11.2565 2.90532e-05 10.9882 2.90538e-05C10.7198 2.90544e-05 10.4624 0.106633 10.2727 0.296389L6 4.57916Z"
                    fill="white"
                  />
                </svg>
              </button>
              { showTerms && <LegalPage data={data?.legalPages?.data} slug="terms" /> }
              { showPrivacy && <LegalPage data={data?.legalPages?.data} slug="privacy" /> }
              { showConfidentiality && <LegalPage data={data?.legalPages?.data} slug="cookies" /> }
            </div>
            {showSignupValidate && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
                    {/* Modal */}
                    <div className="bg-white p-8 rounded-lg shadow-lg w-80">
                        {/* Close button */}
                        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={() => setShowSignupValidate(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {/* Modal content */}
                        <h2 className="text-xl font-bold mb-4">Modal Title</h2>
                        <p>Modal content goes here...</p>
                    </div>
                </div>
            )}
          </div>

        ) }
    </div>
  );
};
