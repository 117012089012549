import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { trackEvent } from "../lib/gmt";
import { useLocation } from "react-router-dom";

const Header = ({ isAuth }) => {
  let location = useLocation();
  const [ menu, toggleMenu ] = useState(false);
  const { instance } = useMsal();
  function handleLogout (instance) {
    const local = localStorage.getItem("isAuthenticatedBp");
    if (local && JSON.parse(local)) {
      localStorage.removeItem("isAuthenticatedBp");
      window.location = "/";
    } else {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    }
  }
  return (
    <section className="header-container fixed w-screen z-50 h-20">
      {/* <!-- nav bar --> */ }
      <div
        id="header-container"
        className="flex flex-col justify-center mx-auto text-gray-50 "
      >
        {/* <!-- nav starts(desktop)--> */ }
        <nav className="hidden md:flex justify-between items-center py-5 px-0 md:px-32">
          <div className="">
            <img className="max-w-[8rem]" src="/images/logo-white.png" alt="" />
          </div>
          { isAuth ? (
            <>
              <div className="flex space-x-5 items-center font-medium">
                <a href="/" className=" text-base font-light px-0 md:px-5"
                  onClick={ () => trackEvent({
                    elementCategory: "Header_Menu",
                    elementName: "Home",
                    eventName: "select_content",
                    pageName: "Home",
                    userLoggued: "Yes"
                  }) }>
                  Home
                </a>

                <a href="/schedule" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
                  elementCategory: "Header_Menu",
                  elementName: location.pathname.replace('/', ''),
                  eventName: "select_content",
                  pageName: location.pathname.replace('/', ''),
                  userLoggued: "Yes"
                }) }>
                  Schedule
                </a>
                <a href="/access" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
                  elementCategory: "Header_Menu",
                  elementName: location.pathname.replace('/', ''),
                  eventName: "select_content",
                  pageName: location.pathname.replace('/', ''),
                  userLoggued: "Yes"
                }) }>
                  Access
                </a>
                <a href="/gallery" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
                  elementCategory: "Header_Menu",
                  elementName: location.pathname.replace('/', ''),
                  eventName: "select_content",
                  pageName: location.pathname.replace('/', ''),
                  userLoggued: "Yes"
                }) }>
                  Gallery
                </a>
                <a href="/abstract" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
                  elementCategory: "Header_Menu",
                  elementName: location.pathname.replace('/', ''),
                  eventName: "select_content",
                  pageName: location.pathname.replace('/', ''),
                  userLoggued: "Yes"
                }) }>
                  Abstract
                </a>
                <span
                  href="#"
                  className="font-light text-base px-0 lg:px-5"
                  onClick={ () => handleLogout(instance) }
                >
                  <button className="bg-[#6DC7DD] h-12 w-32 px-4  text-xl  rounded-md ">
                    Logout
                  </button>
                </span>
              </div>
            </>
          ) : (
            <div className="flex space-x-5 items-center font-medium">
              <span
                  href="#"
                  className="font-light text-base px-0 lg:px-5"
                  onClick={ () => window.location.href = '/' }
                >
                  <button className="bg-[#6DC7DD] h-12 w-32 px-4  text-xl  rounded-md ">
                    Home
                  </button>
                </span>

            </div>

          ) }


        </nav>
        {/* <!-- mobile nav --> */ }
        <nav
          className={
            menu
              ? "mobile-nav flex md:hidden justify-between items-center px-3 py-5"
              : " flex md:hidden justify-between items-center px-3 py-5"
          }
        >
          <img
            className="max-w-[7rem] hover:cursor-pointer"
            src="/images/logo-white.png"
            alt=""
          />
          {/* </span> */ }
          <div
            className="hover:cursor-pointer bg-white rounded-lg p-2"
            onClick={ () => {
              toggleMenu(!menu);
            } }
          >
            <svg
              width="25"
              height="22"
              viewBox="0 0 25 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="25" height="2" rx="1" fill="#1A79C1" />
              <rect y="10" width="25" height="2" rx="1" fill="#1A79C1" />
              <rect y="20" width="25" height="2" rx="1" fill="#1A79C1" />
            </svg>
          </div>
        </nav>
        { menu && (
          <div className="header-menu flex flex-col md:hidden space-y-4 px-3 py-5 z-50">
            <hr className="opacity-25" />
            <a href="/" className=" text-base font-light px-0 md:px-5" onClick={ () => trackEvent({
              elementCategory: "Header_Menu",
              elementName: "Home",
              eventName: "select_content",
              pageName: "Home",
              userLoggued: "Yes"
            }) }>
              Home
            </a>
            <hr className="opacity-25" />
            <a href="/schedule" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
              elementCategory: "Header_Menu",
              elementName: location.pathname.replace('/', ''),
              eventName: "select_content",
              pageName: location.pathname.replace('/', ''),
              userLoggued: "Yes"
            }) }>
              Schedule
            </a>
            <hr className="opacity-25" />
            <a href="/access" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
              elementCategory: "Header_Menu",
              elementName: location.pathname.replace('/', ''),
              eventName: "select_content",
              pageName: location.pathname.replace('/', ''),
              userLoggued: "Yes"
            }) }>
              Access
            </a>
            <hr className="opacity-25" />
            <a href="/gallery" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
              elementCategory: "Header_Menu",
              elementName: location.pathname.replace('/', ''),
              eventName: "select_content",
              pageName: location.pathname.replace('/', ''),
              userLoggued: "Yes"
            }) }>
              Gallery
            </a>
            <hr className="opacity-25" />
            <a href="/abstract" className="font-light text-base px-0 lg:px-5" onClick={ () => trackEvent({
              elementCategory: "Header_Menu",
              elementName: location.pathname.replace('/', ''),
              eventName: "select_content",
              pageName: location.pathname.replace('/', ''),
              userLoggued: "Yes"
            }) }>
              Abstract
            </a>
            <hr className="opacity-25" />
            <span className="font-light text-base px-0 lg:px-5">
              <button className="bg-[#6DC7DD] h-12 w-32 px-4  text-xl  rounded-md " onClick={ () => handleLogout(instance) }>
                Logout
              </button>
            </span>
          </div>
        ) }
      </div>
    </section>
  );
};

export default Header;
