import { gql } from "@apollo/client";
// import substituteVariantsAtRules from "@tailwindcss/postcss7-compat/lib/lib/substituteVariantsAtRules";

const strapiUrl = process.env.REACT_APP_BACKEND_URL;

export const _get = async (url) => {
  const res = await fetch(`${strapiUrl}/${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: process.env.REACT_API_TOKEN,
    },
  });
  const json = await res.json();
  if (json.errors) {
    throw new Error("feild to fetch");
  }
  return json;
};
export const GET_USER = gql`
  query user($email: String) {
    subscribers(filters: { email: { eq: $email }, isValid: {eq: true} }) {
      data {
        id
        attributes {
          email
        }
      }
    }
  }
`;
export const GET_HOME_PAGE_DATA = gql`
  query getHomeData {
    loginPage {
      data {
        attributes {
          Welcome
          EventDateAndLocation
          Presentation
          PresentationServier
          PresentationShinedocs
          TitleServier
          TitleShineDocs
          enableLogin
          background {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    quota {data {attributes {availablePlaces initialQuota}}}
    logo {
      data {
        attributes {
          logoSeedpods {
            data {
              attributes {
                name
                url
                width
                height
              }
            }
          }
          shinedocsBlue {
            data {
              attributes {
                name
                url
                width
                height
              }
            }
          }
          shinedocsYellow {
            data {
              attributes {
                name
                url
                width
                height
              }
            }
          }
        }
      }
    }
    legalPages {
      data {
        attributes {
          title
          content
          slug
        }
      }
    }
    homePage  {
      data {
        attributes {
          WelcomeMessage {
            title
            text
            anchorText
          }
          VideoBlock {
            title
            description
            url
            showVideo
          }
          content {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          gallery {
            data {
              attributes {
                url
              }
            }
          }
          image  {data {attributes {url}}}
          body
          comityImage  {data {attributes {url}}}
          comityTitle
          comityBody
          showVideo
          videoUrl
        }
      }
    }
    abstractPage {
      data {
        attributes {
          title
          description
        }
      }
    }
    abstracts (pagination: { limit: -1 }, sort: ["order:ASC", "title:ASC"]) {
      data {
        attributes {
          title
          body
          order
          abstract_category {
            data {
              attributes {
                name
                label
                order
              }
            }
          }
        }
      }
    }
    abstractCategories (pagination: { limit: -1 }, sort: ["order:ASC", "label:ASC"]) {
      data {
        attributes {
          name
          label
          order
        }
      }
    }
    access {
      data {
        attributes {
          plan  {data {attributes {url}}}
          transport {data {attributes {url}}}
        }
      }
    }
    pdfProgram {
      data {
        attributes {
          pdf  {data {attributes {url name}}}
        }
      }
    }
    sponsor {
      data {
        attributes {
          showInHomePage
          logos {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
    schedules(sort: ["date:DESC", "time:DESC"]) {
      data {
        attributes {
          title
          date
          time
          endTime
          pdf {
            data {
              attributes {
                url
              }
            }
          }
          video {
            videoLink
            videoTitle
          }
          sessions(sort: ["time:ASC"], pagination: { limit: -1, start: 0 }) {
            data {
              attributes {
                title
                time
                sub_sessions(sort:["index:ASC"],pagination: { limit: -1, start: 0 }) {
                  data {
                    attributes {
                      title
                      department
                      name
                      image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    sliders {
      data {
        attributes {
          title
          link
          linkTitle
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }

    gallaries {
      data {
        attributes {
          videos {
            description
            thumbnail {
              data {
                attributes {
                  url
                }
              }
            }
            video {
              data {
                attributes {
                  url
                }
              }
            }
            
          }
          images {
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
            
          }
        }
      }
    }
  }
`;

export const GET_GALLERY_DATA = gql`
  query getGalleries {
    gallaries {
      data {
        attributes {
          videos {
            description
            thumbnail {
              data {
                attributes {
                  url
                }
              }
            }
            video {
              data {
                attributes {
                  url
                }
              }
            }
            
          }
          images {
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
            
          }
        }
      }
    }
  }
`;