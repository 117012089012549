import React, { useState } from "react";
import _ from 'lodash';
import { useEffect } from "react";
import ScrollToTopButton from "../components/scrollTop";
import { Accordion } from "../components/Accordion";
import { scrollToElement } from "../lib/utils";
import { trackEvent, trackPageview } from "../lib/gmt";



const AbstractPage = ({ data }) => {

    const [ categories, setCategories ] = useState([]);

    React.useEffect(() => {
        trackPageview({
            pageName: "Abstract",
            userLoggued: "Yes",
        });
    }, []);



    useEffect(() => {
        const categories = data.abstractCategories.data.map(cat => ({ name: cat.attributes.label, value: cat.attributes.name }));
        setCategories(categories)
    }, []);
    const groupedItems = data.abstracts.data.reduce((acc, item) => {

        if (item.attributes.abstract_category && item.attributes.abstract_category.data && !acc[ item.attributes.abstract_category?.data?.attributes?.label ]) {
            acc[ item.attributes.abstract_category?.data?.attributes?.label ] = [];
        }
        if (item.attributes.abstract_category && item.attributes.abstract_category.data)
            acc[ item.attributes.abstract_category?.data?.attributes?.label ].push(item);
        return acc;
    }, {});
    return (
        <>
            <ScrollToTopButton />
            <div className="w-full flex h-[400px] bg-cover text-[#D4DEF2] justify-center items-center poppins mt-[-20px]" style={ { backgroundImage: "url('/images/bg-new.jpg')" } }>
                <div className="flex flex-col justify-center items-center text-center">
                    <h1 className="text-[40px] w-[265px] leading-[60px] font-bold">{ data.abstractPage.data.attributes.title }</h1>
                    <span className="mt-[32px]">{ data.abstractPage.data.attributes.description }</span>
                    <div className="w-full mt-[8px] rounded-2xl poppins flex flex-col md:w-[600px] mt-[48px]">
                        <label
                            className=" font-normal text-left"
                            htmlFor="grid-first-name"
                        >
                            Search by Category
                        </label>
                        <select
                            onChange={ (e) => {
                                trackEvent({
                                    elementCategory: "search by category",
                                    elementName: e.target.value,
                                    eventName: "select_content",
                                    pageName: "Abstract",
                                    userLoggued: "Yes"
                                });
                                scrollToElement(e.target.value.replace(' ', '-').toLowerCase())
                            } }
                            className="block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"

                        >
                            {
                                categories.map(category => (<option value={ category.name }>{ category.name }</option>))
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="justify-between w-full sm:w-11/12 space-y-10 items-center max-w-[90rem] mx-auto px-4 flex flex-col" style={ { width: "90%", margin: "0 auto", marginTop: '100px', paddingBottom: '40px' } }>
                { Object.entries(groupedItems).map(([ category, items ]) => (
                    <div id={ category.replace(' ', '-').toLowerCase() } key={ category } className="w-full">
                        <h2 className="text-[#1C1A50] font-bold text-[24px] text-center">{ category }</h2>
                        <ul>
                            { _.sortBy(items, [ 'order' ]).map((abstract, index) => (
                                <Accordion abstract={ abstract } index={ index + 1 } />
                            )) }
                        </ul>
                    </div>
                )) }

            </div>
        </>

    )
}

export default AbstractPage;