import { useState } from "react";
import { trackEvent } from "../lib/gmt";

const Tag = ({ children }) => {
    return (
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs  poppins mr-2 mb-2 text-[#323C8E] bg-[#AEC3ED] font-medium">
            { children }
        </span>
    );
};

export const Accordion = ({ abstract, index }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
        trackEvent({
            elementCategory: abstract.attributes.abstract_category.data.attributes.label,
            elementName: abstract.attributes.title,
            eventName: "select_content",
            pageName: "Abstract",
            userLoggued: "Yes"
          });
    };
    return (
        <div className="justify-between w-full sm:w-11/12 space-y-10 items-center mx-auto px-4 mt-[48px] text-[#1C1A50] poppins">
            <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={ toggleAccordion }
            >
                <div>
                    <h3 className="text-lg font-semibold text-[#3D51A3] text-[20px] poppins font-semibold">{ abstract.attributes.title }</h3>
                    <Tag>
                        { abstract.attributes.abstract_category.data.attributes.label }
                    </Tag>
                </div>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={ `transition-transform transform ${isOpen ? 'rotate-180' : 'rotate-0'}` }>
                    <path d="M11 6.5L6 1.5L1 6.5" stroke="#9747FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
               
            </div>
            { isOpen && (
                <div className="p-4">
                    <div dangerouslySetInnerHTML={ { __html: abstract.attributes.body } } />
                </div>
            ) }
            <hr class="border-5 bg-gradient-to-r from-transparent via-[#9747FF] to-transparent" />

        </div>
    )
}