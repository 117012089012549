import React, { Suspense } from "react";
import { Switch, useParams } from "react-router-dom";
import Cookies from "../../components/Confidentiality";
import { LoginPage } from "../../Pages/LoginPage";
import Privacy from "../../components/privacy";
import Terms from "../../components/terms";
import { isAuth } from "../../lib/utils";
import AbstractPage from "../../Pages/AbstractPage";
import LegalPage from "../../components/LegalPage";

const HomePage = React.lazy(() => import("../../Pages/HomePage"));
const GalleryPage = React.lazy(() => import("../../Pages/GalleryPage"));
const SchedulePage = React.lazy(() => import("../../Pages/SchedulePage"));
const AccessPage = React.lazy(() => import("../../Pages/AccessPage"));
const GuestRoute = React.lazy(() => import("../Guest"));
const ProtectedRoute = React.lazy(() => import("../Protected"));


export const AllRoutes = ({ error, loading, data }) => {
  // GuestRoutes : can be accessed by anyone
  // Protected Routes : only logged users can access them
  return (
    <Suspense fallback={ <div className="dot-flashing loading-global"></div> }>
      <Switch>
        {/* should be the first route */ }
        <GuestRoute
          path="/"
          exact
          componentToRender={ (otherProps) => (
            isAuth() ? <HomePage { ...otherProps } data={ data } /> : <LoginPage data={ data } />
          ) }
        />
        <GuestRoute
          path="/reset-password"
          exact
          componentToRender={ (otherProps) => (
            <LoginPage data={ data } />
          ) }
        />
        <GuestRoute
          path="/validate-user/:userId"
          exact
          componentToRender={ (otherProps) => (
            <LoginPage data={ data } />
          ) }
        />
        <GuestRoute
          path="/privacy"
          exact
          componentToRender={ (otherProps) => (
            <LegalPage { ...otherProps } data={ data?.legalPages?.data } slug="privacy" />
          ) }
        />

        <GuestRoute
          path="/terms"
          exact
          componentToRender={ (otherProps) => (
            <LegalPage { ...otherProps } data={ data?.legalPages?.data } slug="terms" />
          ) }
        />

        <GuestRoute
          path="/cookies"
          exact
          componentToRender={ (otherProps) => (
            <LegalPage { ...otherProps } data={ data?.legalPages?.data } slug="cookies" />
          ) }
        />
        <ProtectedRoute
          path="/gallery"
          exact
          componentToRender={ (otherProps) => (
            <GalleryPage { ...otherProps } data={ data } />
          ) }
        />
        <ProtectedRoute
          path="/access"
          exact
          componentToRender={ (otherProps) => (
            <AccessPage { ...otherProps } data={ data?.access?.data?.attributes } />
          ) }
        />
        <ProtectedRoute
          path="/schedule"
          exact
          componentToRender={ (otherProps) => (
            <SchedulePage { ...otherProps } data={ data } />
          ) }
        />

        <ProtectedRoute
          path="/abstract"
          exact
          componentToRender={ (otherProps) => (
            <AbstractPage { ...otherProps } data={ data } />
          ) }
        />

        
        {/* should be the last route */ }
        <GuestRoute
          componentToRender={ (otherProps) => (
            <></>
          ) }
        />
      </Switch>
    </Suspense>
  );
};
