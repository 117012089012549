export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export function getLastPathElement (path) {
  try {
    const pathElements = path.split('/')
    return pathElements[ pathElements.length - 1 ]
  } catch (e) {
    return ''
  }
}

export function allStringsToLowercase (object) {
  try {
    return Object.entries(object).reduce((mutated, [ key, value ]) => {
      if (typeof value !== 'string') return { ...mutated, [ key ]: value }

      return { ...mutated, [ key ]: value.toLocaleLowerCase() }
    }, {})
  } catch (e) {
    return object
  }
}

export function removeUndefined (object) {
  return Object.entries(object).reduce((cleaned, [ key, value ]) => {
    if (value == null) return cleaned

    return { ...cleaned, [ key ]: value }
  }, {})
}

export const isAuth = () => {
  const _authenticated = localStorage.getItem("isAuthenticatedBp");
  return JSON.parse(_authenticated);
}

export const setIsAuthenticated = (value) => {
  localStorage.setItem("isAuthenticatedBp", value);
}