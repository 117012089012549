// import Link from 'next/link'
// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className=" bg-[#323C8E] bg-no-repeat px-4 text-white text-center">
      <div className="justify-between w-9/12 items-center max-w-[90rem] mx-auto px-4 ">
        <div className="flex items-center justify-center">
          <img className="w-48" src="/images/logo-white.png" alt="" />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 space-y-4 justify-between">
          <p className="text-gray-500 mt-[1.1rem]">
            <a href="/">© Copyright 2021 Servier</a>
          </p>
          <a href="/privacy">
            <span className="text-gray-400">
              Privacy Policy
            </span>
          </a>
          <a href="/terms">
            <span className="text-gray-400">
              Terms &amp; Conditions
            </span>
          </a>
          <a href="/cookies">
            <span className="text-gray-400">
              Cookies policy
            </span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
