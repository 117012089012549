import { useMsal } from "@azure/msal-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import request from "../lib/axios";

export const RegisterForm = ({
    enableLogin,
    quota,
    loading,
    setLoading,
    setView,
    setShowTerms,
    setShowPrivacy,
    setShowConfidentiality,
    showValidationStatus
}) => {
    const myRef = useRef(null);
    const [ done, setDone ] = useState(false);

    const { register, reset, handleSubmit } = useForm();
    const [ parking, setParking ] = useState(false);
    const [ lunch, setLunch ] = useState(false);
    const [ onSite, setOnSite ] = useState('On site at Saclay');
    const [ userType, setUserType ] = useState('external');
    const [ consentChecked, setConsentChecked ] = useState(false);
    const [ isError, setIsError ] = useState(true);
    const [ response, setResponse ] = useState(null);
    const [ consentPhotoChecked, setConsentPhotoChecked ] = useState(false);

    const onSubmit = async (fields) => {
        setLoading(true);
        try {
            fields.lunch = fields.lunch === "false";
            fields.parking = fields.parking === "false";
            fields.consent = "yes";
            fields.attending = onSite;
            fields.userType = userType;
            await request.post("/api/subscribers", { data: fields });
            setDone(true);
            reset({
                FirstName: "",
                LastName: "",
                email: "",
                attending: "On site at Saclay",
                attendingOnSite: "All day (from 9:30 am to 6 pm)",
                parking: false,
                lunch: false,
                userType: 'external'
            });
            setConsentChecked(false);
            setConsentPhotoChecked(false);
            setResponse("Your registration has been successfully processed. You will receive an email with a link to activate your account.");
            setIsError(false);
        } catch (error) {
            console.log(error);
            if (error.response.data.error.message.indexOf("unique") > -1) {
                setIsError(true);
                setResponse("Email address is already registred");
            }
            setDone(false);
        }
        setLoading(false);
    };
    const handleOnsiteChange = (event) => {
        setOnSite(event.target.value);
    };
    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
    };

    return (
        <form ref={ myRef } className="flex flex-col" onSubmit={ handleSubmit(onSubmit) }>
            <div className="pt-4 md:px-7 px-5 text-[#1C1A50] poppins text-base">
                <h1 className=" font-bold text-[32px] text-center">
                    Register
                </h1>
                <div className="flex gap-4">
                    <div className="w-full mt-[8px] rounded-2xl">
                        <label
                            className="  font-normal"
                            htmlFor="grid-first-name"
                        >
                            First Name <span className="text-[#CC2D2D]">*</span>
                        </label>
                        <input
                            className="appearance-none block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                            id="FirstName"
                            type="text"
                            placeholder="Enter here"
                            { ...register("FirstName", {
                                required: "please enter you first name",
                            }) }
                        />
                    </div>
                    <div className="w-full mt-[8px] rounded-2xl">
                        <label
                            className=" font-normal"
                            htmlFor="grid-first-name"
                        >
                            Last Name <span className="text-[#CC2D2D]">*</span>
                        </label>
                        <input
                            className="appearance-none block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                            id="LastName"
                            type="text"
                            placeholder="Enter here"
                            { ...register("LastName", {
                                required: "Please enter you last name",
                            }) }
                        />
                    </div>
                </div>


                <div className="w-full mt-[8px] rounded-2xl">
                    <label
                        className=" font-normal"
                        htmlFor="email"
                    >
                        Email <span className="text-[#CC2D2D]">*</span>
                    </label>
                    <input
                        className="appearance-none block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                        id="email"
                        type="text"
                        placeholder="Enter here"
                        { ...register("email", {
                            required: "Please enter a valid email address",
                        }) }
                    />
                </div>
                <div className="w-full mt-[8px] rounded-2xl">
                    <label
                        className=" font-normal"
                        htmlFor="email"
                    >
                        Password <span className="text-[#CC2D2D]">*</span>
                    </label>
                    <input
                        className="appearance-none block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                        id="password"
                        type="password"
                        placeholder="Enter here"
                        { ...register("password", {
                            required: "Please enter a valid password",
                        }) }
                    />
                </div>
                <div className="w-full mt-[8px] rounded-2xl">
                    <label
                        className=" font-normal"
                        htmlFor="attending"
                    >
                        How will you be attending Seedpods day?
                    </label>
                    <select
                        value={ onSite }
                        name="attending"
                        id="attending"
                        onChange={ handleOnsiteChange }
                        className="block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                        placeholder="Enter here"
                    >
                        <option value={ 'On site at Saclay' }>On site at Saclay (capacity { quota } people)</option>
                        <option value={ 'Online' }>Online</option>
                    </select>
                </div>
                <div className="w-full mt-[8px] rounded-2xl">
                    <label
                        className=" font-normal"
                        htmlFor="attending"
                    >
                        Are you internal or external ?
                    </label>
                    <select
                        value={ userType }
                        name="userType"
                        onChange={ handleUserTypeChange }
                        className="block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                    >
                        <option value={ 'internal' }>Internal</option>
                        <option value={ 'external' }>External</option>
                    </select>
                </div>
                <div className="w-full mt-[8px] rounded-2xl">
                    <label
                        className=" font-normal"
                        htmlFor="grid-first-name"
                    >
                        { userType !== 'internal' ? 'Organization Name' : 'Departement' } <span className="text-[#CC2D2D]">*</span>
                    </label>
                    <input
                        className="appearance-none block w-full  font-normal text-sm placeholder-black text-black  focus:outline-none border border-[#323C8E] rounded-lg p-3 h-12"
                        id="Organization"
                        type="text"
                        placeholder="Enter here"
                        { ...register("Organization", {
                            required: "Please enter you organization name",
                        }) }
                    />
                </div>
                { (onSite !== 'Online' && userType === 'external') && (
                    <div className="flex">
                        <label className="block md:text-sm text-xs font-normal cursor-pointer">
                            <input
                                value={ parking }
                                onChange={ (e) => setParking(e.target.checked) }
                                className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                                name="parking"
                                id="parking"
                                type="checkbox"
                                { ...register("parking") }
                            />
                            Need parking?
                        </label>
                        <label className="block md:text-sm text-xs font-normal cursor-pointer ml-5">
                            <input
                                value={ lunch }
                                onChange={ (e) => setLunch(e.target.checked) }
                                className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                                name="lunch"
                                id="lunch"
                                type="checkbox"
                                { ...register("lunch") }
                            />
                            Need lunch?
                        </label>
                    </div>
                ) }

                { onSite !== 'Online' && (
                    <>
                        <p className="text-[#1A79C1] md:text-base text-sm font-semibold md:pt-6 pt-4">
                            If attending on site, will you be coming?
                        </p>
                        <label className="block text-2x1 roboto md:text-sm text-xs font-normal cursor-pointer">
                            <input
                                className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                                name="attendingOnSite"
                                type="radio"
                                value="All day (from 9:30 am to 6 pm)"
                                defaultChecked
                                { ...register("attendingOnSite", {
                                    required: "Please select attending session",
                                }) }
                            />
                            All day (from 9:30 am to 6 pm)
                        </label>
                        <label className="block md:text-sm text-xs font-normal cursor-pointer">
                            <input
                                className="md:mt-2 mt-1 mr-1 cursor-pointer bg-[#0171B9]"
                                name="attendingOnSite"
                                type="radio"
                                value="Morning session (9:30 am to 1 pm)"
                                { ...register("attendingOnSite", {
                                    required: "Please select attending session",
                                }) }
                            />
                            Morning session (9:30 am to 1 pm)
                        </label>
                        <label className="block md:text-sm text-xs font-normal cursor-pointer">
                            <input
                                className="md:mt-2 mt-1 mr-1 cursor-pointer bg-[#0171B9]"
                                name="attendingOnSite"
                                type="radio"
                                value="Afternoon session (2.30pm to 5.30pm)"
                                { ...register("attendingOnSite", {
                                    required: "Please select attending session",
                                }) }
                            />
                            Afternoon session (2.30 pm to 6 pm)
                        </label>
                    </>
                ) }


                <div className="flex">
                    <label className="block md:text-sm text-xs font-normal cursor-pointer">
                        <input
                            className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                            name="consentPhoto"
                            type="checkbox"
                            value="yes"
                            checked={ consentPhotoChecked }
                            onClick={ (e) => setConsentPhotoChecked(e.target.checked) }
                        />
                        By attending this event you give permission to be photographed and to use the photos for internal and external communication.
                    </label>
                </div>
                <p className="md:text-sm text-xs font-normal">
                    <input
                        className="md:mt-3 mt-2 mr-1 cursor-pointer bg-[#0171B9]"
                        name="consent"
                        id="consentCheckbox"
                        type="checkbox"
                        value="yes"
                        checked={ consentChecked }
                        onChange={ (e) => setConsentChecked(e.target.checked) }
                    />
                    I agree to the{ " " }
                    <span
                        className="underline hover:text-gray-500 hover:cursor-pointer"
                        onClick={ () => setShowConfidentiality(true) }
                    >
                        confidentiality commitment
                    </span>
                    ,{ " " }
                    <span
                        className="underline hover:text-gray-500 hover:cursor-pointer"
                        onClick={ () => setShowTerms(true) }
                    >
                        terms and conditions
                    </span>{ " " }
                    and the{ " " }
                    <span
                        className="underline hover:text-gray-500 hover:cursor-pointer"
                        onClick={ () => setShowPrivacy(true) }
                    >
                        privacy policy
                    </span>
                </p>
                { response && isError && (

                    <div className="w-full mt-[8px] rounded-2xl bg-gray-100 border border-white error box">
                        { response }
                    </div>

                ) }

                { response && !isError && (

                    <div className="w-full mt-[8px] rounded-2xl bg-gray-100 border border-white success box box1">
                        { response }
                    </div>

                ) }

                {
                    showValidationStatus && (
                        <div className="w-full mt-4 flex mt-[10px] rounded-2xl text-[#973939] success box box1">
                            Registration successfully validated.
                        </div>
                    )
                }

                <div className="my-8 text-center flex">
                    <button
                        className={ `bg-[#24226A] text-white flex justify-between items-center gap-2 text-white border py-3 px-10 md:font-semibold font-normal rounded-lg` }
                        disabled={ loading || !consentChecked || !consentPhotoChecked }
                        style={ loading || !consentChecked || !consentPhotoChecked ? { opacity: "0.3" } : {} }
                        type="submit"
                    >
                        { loading && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                className={ `motion-reduce:hidden animate-spin` }
                                fill="none"
                                viewBox="0 0 24 24"
                                focusable="false"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                />
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                />
                            </svg>
                        ) }
                        Register
                    </button>
                </div>
                { enableLogin && (<span>
                    Already registred? <a className="text-[#1A79C1] hover:underline" href="#" onClick={ () => setView('login') }>Click here to Sign in</a>
                </span>) }
            </div>
        </form>
    )
};