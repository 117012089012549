import React from "react";
const Privacy = ({ data, slug }) => {
  return (
    <div>
      <section className="py-20" id="about-us">
        <div className="flex flex-col lg:mt-10 space-y-10 lg:flex-row lg:space-y-0 lg:space-x-16 items-center max-w-[90rem] mx-auto px-4">
          <div className="md:flex justify-center space-y-4">
            <div className="flex-col lg:w-10/12 space-y-4">
              <div dangerouslySetInnerHTML={ { __html: data.find(page => page.attributes.slug === "privacy")?.attributes.content } } />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Privacy;
