import React from "react";

import ReactGA from 'react-ga';

import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./lib/Appllo/client";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";

const TRACKING_ID = "G-525ZFG3158";

ReactGA.initialize(TRACKING_ID);

function App () {

  const apolloClient = useApollo(null);

  /*React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);*/

  return (
    <ApolloProvider client={ apolloClient }>
      <BrowserRouter>
          <Layout />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
