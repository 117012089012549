import { useQuery } from '@apollo/client';
import React from 'react';
import { GET_HOME_PAGE_DATA, GET_LOGIN_PAGE_DATA } from '../lib/api';
import { isAuth } from '../lib/utils';
import { AllRoutes } from '../Routes/AllRoutes';
import Footer from './Footer';
import Header from './Header';
import { LoginPage } from '../Pages/LoginPage';
import { useLocation } from 'react-router-dom';
const Layout = () => {
    const location = useLocation();
    const { error, loading, data } = useQuery(GET_HOME_PAGE_DATA);

    if (loading)
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="grid grid-rows-2 ">
                    <img src="/images/loader.gif" className="h-32" alt="" />
                    <div className="text-center">Loading... </div>
                </div>
            </div>
        );

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="grid grid-rows-2 ">
                    <div className="text-center">occured some error </div>
                </div>
            </div>
        );
    }
    return (

        <div className='flex flex-col h-screen justify-between'>
            {(isAuth() || !isAuth() &&  (location.pathname !== "/" && location.pathname !== "/reset-password" && !location.pathname.startsWith("/validate-user") )) && (<Header isAuth={ isAuth() } />)}
            <main
                id="App"
                className={`main-content font-sans bg-[url('/images/background-watermark.png')] bg-no-repeat bg-cover ${isAuth() ? "mt-24" : ""}`}

            >
                <AllRoutes error={ error } loading={ loading } data={ data } />
            </main>
            <Footer />
        </div>

    );
};
export default Layout;