import React from "react";
const Confidentiality = () => {
  return (
    <div>
      <section className="py-8">
        <div className="flex flex-col space-y-10 lg:mt-12 lg:flex-row lg:space-y-0 lg:space-x-16 items-center max-w-[90rem] mx-auto lg:px-4">
          <div className="md:flex justify-center space-y-4 ">
            <div className="flex-col space-y-4 lg:w-10/12">
              <h1 className="text-[2rem] font-bold">
                CONFIDENTIALITY COMMITMENT
              </h1>
              <div className="space-y-2 mt-5 text-[#606060] font-nonito">
                <div>I, the undersigned,</div>
                <ol className="list-decimal ml-4">
                  <li>
                    Will participate to:
                    <ul className="list-disc ml-5 mt-3">
                      <li>
                        The following event organized by Servier (hereinafter
                        the “Event”):
                        <div className="mt-2 font-bold">
                          SeedPods Day &mdash; 29 JUIN 2023 &mdash; Parise,
                          France.
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Acknowledge to be led to have access to confidential
                    information (hereinafter the{" "}
                    <span className="underline">
                      “Confidential Information”
                    </span>
                    ) before, during or after my participation to the Event.
                    <ul className="list-disc ml-5 mt-3">
                      <li>
                        Said Confidential Information shall be understood as all
                        information, in particular regarding samples, devices
                        and/or data of any kind (especially, without limitation,
                        of scientific, technical, commercial, administrative or
                        financial nature) that I had access to or will be
                        communicated to me, either orally, in writing or in any
                        other medium before, during or after the Event.
                      </li>
                      <li>
                        If necessary, specific Confidential Information
                        disclosed to me shall be listedon the back of this
                        document.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Agree:
                    <ul className="list-disc ml-5 mt-3">
                      <li>
                        to respect, protect and maintain the strict confidential
                        character of the Confidential Information that will be
                        communicated to me before, during or after the Event;
                      </li>
                      <li>
                        to take all necessary precautions so that third parties
                        cannot have access to said Confidential Information;
                      </li>
                      <li>
                        not to use Confidential Information for other purpose
                        than the Event;
                      </li>
                      <li>
                        to take all measures to ensure the respect of my
                        obligations.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Am not bound by confidentiality obligations regarding
                    Confidential Information that I can prove in writing:
                    <ul className="list-disc ml-5 mt-3">
                      <li>
                        was already in the public domain when I received it or
                        has fallen into the public domain after being disclosed
                        to me with no fault on my part;
                      </li>
                      <li>
                        was already in my possession at the time of disclosure;
                      </li>
                      <li>
                        must be revealed because of the law, a governmental
                        authority or by a definitive judicial decision. In this
                        respect, I agree to only reveal Confidential Information
                        that is strictly necessary to satisfy such legal
                        obligations.
                      </li>
                    </ul>
                  </li>
                </ol>
                <div className="mt-5">
                  <span className="font-bold">
                    This commitment is effective as from the signature date and
                    for a period of ten (10) years thereafter.
                  </span>{" "}
                  This commitment shall be governed and construed in accordance
                  with the laws of France. All disputes shall be subject to the
                  exclusive jurisdiction of French courts.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Confidentiality;
